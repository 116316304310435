// ThresholdNotification.js
import React, { useState, useEffect } from 'react';
import { Badge, IconButton, Menu, MenuItem, Text } from '@chakra-ui/react';
import { EmailIcon } from '@chakra-ui/icons';
import axios from 'axios';

const ThresholdNotification = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [thresholdAlerts, setThresholdAlerts] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0); // Définir unreadCount dans l'état local

    useEffect(() => {
        // Logique pour obtenir les alertes de seuil depuis votre API ou un autre service
        // Par exemple, vous pouvez utiliser Axios pour appeler votre API
        axios.get('https://applogapi.mariabird.net/api/AlertProduct')
            .then(response => {
                // Mettre à jour l'état des alertes de seuil avec les données de la réponse
                setThresholdAlerts(response.data);

                // Mettre à jour le nombre de notifications non lues
                const count = response.data.filter(alert => !alert.read).length;
                setUnreadCount(count);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des alertes de seuil', error);
            });
    }, []);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton colorScheme="blue" onClick={handleClick} icon={<Badge badgeContent={unreadCount} colorScheme="red"><EmailIcon /></Badge>}/>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {thresholdAlerts.map(alert => (
                    <MenuItem key={alert.id}>
                        <Text>{alert.message}</Text>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default ThresholdNotification;
