// Chakra imports
import {
    Flex,
    Grid,
    Image,
    SimpleGrid,
    useColorModeValue,
  } from "@chakra-ui/react";
  import InfoProjectsAccepterDemande from "../Dashboard/components/DemandeProject/InfoProjectAccepte";
  import InfoProjectsRefuserDemande from "../Dashboard/components/DemandeProject/InfoProjectRefuser";
  import InfoProjectsWaitingDemande from "../Dashboard/components/DemandeProject/InfoProjectWaiting";
  import ListeDemande from "../Dashboard/components/DemandeProject/ListeDemande";
  import React from "react";

  
  export default function DemandeProjects() {

  
    return (
      <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
        <Grid
          templateColumns={{ md: "1fr" }}
          templateRows={{ md: "1fr auto", lg: "1fr" }}
          my="26px"
          gap="24px"
        >
          <ListeDemande />
          <InfoProjectsAccepterDemande />
          <InfoProjectsWaitingDemande />
          <InfoProjectsRefuserDemande />
        </Grid>
      </Flex>
    );
  }
  