import React from 'react';
import { Box, Heading, Text, Grid, Flex } from '@chakra-ui/react';

const Changelog = () => {
  const changelogData = [
    { version: '1.1.7_A', 
      date: '23/04/2024', 
      changes: ['Correction Component "ChangeQuantity"'] },
  ];

  return (
    <Box p="4">
      <Heading as="h1" mb="4">----------------------------</Heading>
      <Grid templateColumns="repeat(auto-fill, minmax(300px, 1fr))" gap="4">
        {changelogData.map(entry => (
          <Box key={entry.version} borderWidth="1px" borderRadius="lg" p="4">
            <Heading as="h2" size="md" mb="2">V.{entry.version} - {entry.date}</Heading>
            <ul>
              {entry.changes.map(change => (
                <li key={change}><Text>{change}</Text></li>
              ))}
            </ul>
          </Box>
        ))}
      </Grid>
    </Box>
  );
};

export default Changelog;
