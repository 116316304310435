import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import { SearchIcon } from '@chakra-ui/icons';

const SearchProduit = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [rackNumber, setRackNumber] = useState('');
  const history = useHistory();

  const handleSearch = () => {
    axios
      .get(
        `https://logapp.api.mrapi.net/api/products?term=${encodeURIComponent(
          searchTerm
        )}`
      )
      .then(response => {
        history.push({
          pathname: '/admin/logistic/racks/details',
          state: { products: response.data },
        });
      })
      .catch(error => {
        console.error('Erreur lors de la recherche de produits :', error);
      });
  };

  const handleSearchByRack = () => {
    axios
      .get(
        `https://logapp.api.mrapi.net/api/products?rackNumber=${encodeURIComponent(
          rackNumber
        )}`
      )
      .then(response => {
        history.push({
          pathname: '/admin/logistic/racks/details',
          state: { products: response.data },
        });
      })
      .catch(error => {
        console.error('Erreur lors de la recherche de produits par support :', error);
      });
  };

  return (
    <Card minHeight="290.5px" p="1.2rem">
      <CardBody w="100%">
        <Flex direction="column" alignItems="center">
          <h1>Recherche de Produits</h1>
          <InputGroup size="md" width="100%" mt="1rem">
            <Input
              pr="4.5rem"
              type="text"
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              placeholder="Entrez le term de la recherche"
              borderColor="gray.300"
              focusBorderColor="blue.500"
              paddingRight="2.5rem"
            />
            <InputRightElement width="4.5rem" top="auto">
              <Button
                h="1.75rem"
                size="sm"
                onClick={handleSearch}
                colorScheme="blue"
              >
                <SearchIcon />
              </Button>
            </InputRightElement>
          </InputGroup>
          <InputGroup size="md" width="100%" mt="1rem">
            <Input
              pr="4.5rem"
              type="text"
              value={rackNumber}
              onChange={e => setRackNumber(e.target.value)}
              placeholder="Numéro de rack"
              borderColor="gray.300"
              focusBorderColor="blue.500"
              paddingRight="2.5rem"
            />
            <InputRightElement width="4.5rem" top="auto">
              <Button
                h="1.75rem"
                size="sm"
                onClick={handleSearchByRack}
                colorScheme="teal"
              >
                <SearchIcon />
              </Button>
            </InputRightElement>
          </InputGroup>
        </Flex>
      </CardBody>
    </Card>
  );
};


export default SearchProduit;
