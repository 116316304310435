import {
  Avatar,
  Flex,
  Icon,
  Image,
  Td,
  Text,
  Tr,
  useColorModeValue,
  Progress,
} from "@chakra-ui/react";
import React from "react";

function formatDateTime(dateTimeString) {
  const options = { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit" };
  const dateTime = new Date(dateTimeString);
  return dateTime.toLocaleDateString("fr-FR", options);
}

function DashboardTableRow(props) {
  const { name, description, images, created_at, updated_at, time_spent, progression } = props;
  const textColor = useColorModeValue("gray.700", "white");
  
  return (
    <Tr>
      <Td minWidth={{ sm: "250px" }} pl="0px">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
        <Image src={images} alt={name} height="50px" width="50px" mr="1rem" />
          <Text
            fontSize="md"
            color={textColor}
            fontWeight="bold"
            minWidth="100%"
          >
            {name}
          </Text>
        </Flex>
      </Td>

      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {description}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {formatDateTime(created_at)}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {formatDateTime(updated_at)}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {time_spent}
        </Text>
      </Td>
      <Td>
        <Progress value={progression} size="sm" />
      </Td>
    </Tr>
  );
}

export default DashboardTableRow;
