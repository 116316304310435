import { useAuth } from "context/AuthContext";
import { useEffect } from 'react';
import {
    Flex,
    Text,
    Avatar,
  } from "@chakra-ui/react";

  const WelcomeUser = () => {
    const { fetchUserDetails } = useAuth();
    const { userDetails } = useAuth();
    const { token } = useAuth();

    useEffect(() => {
      if (token) {
        fetchUserDetails(token);
      }
    }, [token]);

    const currentTime = new Date();
    const currentHour = currentTime.getHours();
    let greeting = "Bonjour";
    if (currentHour >= 18) {
      greeting = "Bonsoir";
    }
  
    return (
      <Flex alignItems="center">
        <Text mr="2">{`${greeting}, ${userDetails?.name}`}</Text>
        <Avatar size="sm" src={userDetails?.avatar} />
      </Flex>
    );
  };
  
  export default WelcomeUser;
