// Chakra imports
import { Flex } from "@chakra-ui/react";
import React from "react";
import Product from "./components/products";
//import Projects from "./components/Projects";
import { tablesTableData, dashboardTableData } from "variables/general";

function ListProduct() {
  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
      <Product
        title={"Liste des produits"}
        captions={[""]}
        data={tablesTableData}
      />
    </Flex>
  );
}

export default ListProduct;
