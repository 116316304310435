// IMPORT DIVERS //
//import Home from "views/Dashboard/Home";
//import Tables from "views/Dashboard/Tables";
//import Billing from "views/Dashboard/Billing";
//import RTLPage from "views/Dashboard/RTL";
//import Profile from "views/Dashboard/Profile";

// IMPORT AUTH //
import SignIn from "views/Auth/SignIn.js";
import SignUp from "views/Auth/SignUp.js";

// IMPORT View Core DashBoard //
import Dashboard from "views/Dashboard/Dashboard";
import ProductDetailPage from "views/Logistic/RackProduit";
import AddProductPage from "views/Dashboard/Dashboard/AddProduct";
import Changelog from "views/Dashboard/ChangeLog";

import ListProduct from "views/Dashboard/Dashboard/ListProduct";
import DemandeProjects from "views/Dashboard/Projects/demandeprojet";
import InfoProjectsArchived from "views/Dashboard/Dashboard/components/DemandeProject/InfoProjectArchived";

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
} from "components/Icons/Icons";

import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { FcMultipleInputs } from "react-icons/fc";
import { UilParcel } from '@iconscout/react-unicons'


var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/logistic/racks/details",
    name: "Racks",
    icon: <FcMultipleInputs color="inherit" />,
    component: ProductDetailPage,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/logistic/racks/product_add",
    name: "Stock",
    icon: <MdOutlineProductionQuantityLimits color="inherit" />,
    component: AddProductPage,
    layout: "/admin",
  },
  {
    path: "/logistic/racks/product",
    name: "Produits",
    icon: <UilParcel color="inherit" />,
    component: ListProduct,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/projects/request",
    name: "Demande de Project",
    icon: <UilParcel color="inherit" />,
    component: DemandeProjects,
    layout: "/admin",
    hidden: false
  },
  {
    path: "/projects/archived",
    name: "Archive des Projet",
    component: InfoProjectsArchived,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/login",
    name: "Login",
    component: SignIn,
    layout: "/auth",
    hidden: true
  },
  {
    path: "/registerTP",
    name: "Register",
    component: SignUp,
    layout: "/auth",
    hidden: true 
  },
  {
    path: "/changelog",
    name: "ChangeLog",
    component: Changelog,
    layout: "/admin",
    hidden: true 
  }
  
];
export default dashRoutes;
