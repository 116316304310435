import React, { useState } from 'react';
import { FormControl, FormLabel, Input, Button, Box, Grid, GridItem } from '@chakra-ui/react';
import axios from 'axios';

const AddProductForm = () => {
  const [productData, setProductData] = useState({
    name: '',
    description: '',
    codeGEN: '',
    reference: '',
    quantity: '',
    rackNumber: '',
    rackSlot: '',
    rackHeight: '',
    rackSide: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductData({ ...productData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://applogapi.mariabird.net/api/product/add', productData);
      console.log(response.data);
    } catch (error) {
      console.error('Erreur lors de l\'envoi du formulaire :', error);
    }
  };

  return (
    <Box p="4" borderWidth="1px" borderRadius="md" boxShadow="md" backgroundColor="#1a202c">

      <form onSubmit={handleSubmit}>
        <FormControl mb="4">
          <FormLabel color="white">Nom du Produit</FormLabel>
          <Input
            type="text"
            name="name"
            value={productData.name}
            onChange={handleChange}
            placeholder="Entrez le nom du produit"
            required
            color="white"
          />
        </FormControl>
        <FormControl mb="4">
          <FormLabel color="white">Description</FormLabel>
          <Input
            type="text"
            name="description"
            value={productData.description}
            onChange={handleChange}
            placeholder="Entrez la description"
            required
            color="white"
          />
        </FormControl>
        <FormControl mb="4">
          <FormLabel color="white">Code GEN</FormLabel>
          <Input
            type="text"
            name="codeGEN"
            value={productData.codeGEN}
            onChange={handleChange}
            placeholder="Entrez le code GEN"
            required
            color="white"
          />
        </FormControl>
        <FormControl mb="4">
          <FormLabel color="white">Référence</FormLabel>
          <Input
            type="text"
            name="reference"
            value={productData.reference}
            onChange={handleChange}
            placeholder="Entrez la référence"
            required
            color="white"
          />
        </FormControl>
        <FormControl mb="4">
          <FormLabel color="white">Quantité</FormLabel>
          <Input
            type="number"
            name="quantity"
            value={productData.quantity}
            onChange={handleChange}
            placeholder="Entrez la quantité"
            required
            color="white"
          />
        </FormControl>
        <Grid templateColumns="repeat(4, 1fr)" gap="4">
          <GridItem colSpan={1}>
            <FormControl mb="4">
              <FormLabel color="white">Numéro du Rack</FormLabel>
              <Input
                type="text"
                name="rackNumber"
                value={productData.rackNumber}
                onChange={handleChange}
                placeholder="Numéro"
                size="sm"
                required
                color="white"
              />
            </FormControl>
          </GridItem>
          <GridItem colSpan={1}>
            <FormControl mb="4">
              <FormLabel color="white">Emplacement</FormLabel>
              <Input
                type="text"
                name="rackSlot"
                value={productData.rackSlot}
                onChange={handleChange}
                placeholder="Emplacement"
                size="sm"
                required
                color="white"
              />
            </FormControl>
          </GridItem>
          <GridItem colSpan={1}>
            <FormControl mb="4">
              <FormLabel color="white">Hauteur</FormLabel>
              <Input
                type="text"
                name="rackHeight"
                value={productData.rackHeight}
                onChange={handleChange}
                placeholder="Hauteur"
                size="sm"
                required
                color="white"
              />
            </FormControl>
          </GridItem>
          <GridItem colSpan={1}>
            <FormControl mb="4">
              <FormLabel color="white">Côté</FormLabel>
              <Input
                type="text"
                name="rackSide"
                value={productData.rackSide}
                onChange={handleChange}
                placeholder="Côté"
                size="sm"
                required
                color="white"
              />
            </FormControl>
          </GridItem>
        </Grid>
        <Button type="submit" colorScheme="teal" mt="4">Ajouter</Button>
      </form>
    </Box>
  );
};

export default AddProductForm;