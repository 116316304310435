import {
  Avatar,
  Flex,
  Icon,
  Image,
  Td,
  Text,
  Tr,
  useColorModeValue,
  Progress,
  Select,
  Button,
} from "@chakra-ui/react";
import React from "react";

function formatDateTime(dateTimeString) {
  const options = { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit" };
  const dateTime = new Date(dateTimeString);
  return dateTime.toLocaleDateString("fr-FR", options);
}

function DemandeProjectTableRow(props) {
  const { id, name, description, created_at, updated_at, time_spent, status, handleStatusChange, viewDetails } = props;
  const textColor = useColorModeValue("gray.700", "white");
  
  return (
    <Tr>
      <Td minWidth={{ sm: "250px" }} pl="0px">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Text
            fontSize="md"
            color={textColor}
            fontWeight="bold"
            minWidth="100%"
          >
            {name}
          </Text>
        </Flex>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {description}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {formatDateTime(created_at)}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {status}
        </Text>
      </Td>
      <Td>
        <Select
          placeholder="Update Status"
          onChange={(e) => handleStatusChange(id, e.target.value)}
        >
          <option value="accepted">Accepté</option>
          <option value="pending">En Attente</option>
          <option value="rejected">Refusé</option>
          <option value="archived">Archivé</option>
        </Select>
      </Td>
      <Td>
        <Button colorScheme="teal" onClick={viewDetails}>Voir Détails</Button>
      </Td>
    </Tr>
  );
}

export default DemandeProjectTableRow;
