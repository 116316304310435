import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Flex, Grid, Text, Input, Button, Box, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, useToast } from "@chakra-ui/react";
import { WarningTwoIcon, EditIcon } from '@chakra-ui/icons';
import { useAuth } from 'context/AuthContext';
import QuantityControl from 'views/Dashboard/Dashboard/components/QuantityControl';
import axios from 'axios';
import './stylescard.css';
const api = axios.create({
  baseURL: 'https://logapp.api.mrapi.net/api',
})


const turnOnLED = (rackLocation) => {
  console.log(`Turning on LED for rack location: ${rackLocation}`);
};

const ProductDetailPage = () => {
  const location = useLocation();
  const searchTerm = new URLSearchParams(location.search).get('term');
  const products = location.state && location.state.products;
  const [isOpen, setIsOpen] = useState(false);
  const [isEMOpen, setIsEMOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [seuil, setSeuil] = useState(selectedProduct ? selectedProduct.seuil : 0);
  const { token } = useAuth();

  const [loading, setLoading] = useState(false);
  const toast = useToast();

  if (!products || products.length === 0) {
    return <div>Aucun produit trouvé pour le terme "{searchTerm}"</div>;
  }

  const formatRackLocation = (product) => {
    return `${product.rackNumber} ${product.rackSlot} ${product.rackHeight} ${product.rackSide}`;
  };

  const handleLocateClick = async (product) => {
    const rackLocation = formatRackLocation(product);
    try {
      setLoading(true);
      await api.get(`/locate/${product.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      turnOnLED(rackLocation);
      toast({
        title: `LEDs activated for ${rackLocation}`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      setLoading(false);
      console.error('Error while activating LEDs:', error);
      toast({
        title: "Error activating LEDs",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleQuantityChange = async (newQuantity) => {
    if (!selectedProduct) {
      console.error('Aucun produit sélectionné pour modifier la quantité.');
      return;
    }
  
    try {
      const response = await api.patch(`/products/${selectedProduct.id}`, { quantity: newQuantity });
      console.log('Quantité du produit mise à jour avec succès :', response.data);
    
      setSelectedProduct(prevProduct => ({
        ...prevProduct,
        quantity: newQuantity
      }));
    
      toast({
        title: "Quantité mise à jour avec succès",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la quantité du produit :', error);
      toast({
        title: "Erreur lors de la mise à jour de la quantité du produit",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  

  const handleOpenModal = (product) => {
    setSelectedProduct(product);
    setIsOpen(true);
  };

  const handleOpenEditModal = (product) => {
    setSelectedProduct(product);
    setIsEMOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedProduct(null);
    setIsOpen(false);
  };

  const handleCloseEditModal = () => {
    setSelectedProduct(null);
    setIsEMOpen(false);
  };

  const handleUpdateSeuil = async (seuil) => {
    if (!selectedProduct) {
      console.error('Aucun produit sélectionné pour modifier la quantité.');
      return;
    }
  
    try {
      const response = await api.put(`/products/${selectedProduct.id}/update`, { seuil: newSeuil });
      console.log('Seuil mis à jour avec succès :', response.data);
  
      setSelectedProduct(prevProduct => ({
        ...prevProduct,
        seuil: newSeuil
      }));
      handleCloseEditModal();
    } catch (error) {
      console.error('Erreur lors de la mise à jour du produit :', error);
      toast({
        title: "Erreur lors de la mise à jour du produit",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: false,
      });
    }
  };
  

const handleDeleteProduct = async () => {
  if (!selectedProduct) {
    console.error('Aucun produit sélectionné pour la suppression.');
    return;
  }

  try {
    await api.delete(`/produit/${selectedProduct.id}/deleted`);
    console.log('Produit supprimé avec succès');
    handleCloseEditModal();
  } catch (error) {
    console.error('Erreur lors de la suppression du produit :', error);
    toast({
      title: "Erreur lors de la suppression du produit",
      description: error.message,
      status: "error",
      duration: 5000,
      isClosable: false,
    });
  }
};


  return (
    <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }}>
      Voici tous les Emplacements pour votre recherche {searchTerm}

      <Grid
        gap={6}
        templateColumns={{ base: "1fr", md: "1fr 1fr" }}
      >
        {products.map((product, index) => (
          <Box key={index} p="4" borderWidth="1px" borderRadius="md" boxShadow="md" backgroundColor="#1a202c" display="flex" alignItems="center">
          <div style={{ flex: 1 }}>
            <h1>Détails du Produit "{product.name}"</h1>
            <p>
              Emplacement :&nbsp; 
              <span className="rack-location">
                {formatRackLocation(product)}
              </span>
            </p>
            <Text>Nom du Produit: {product.name}</Text>
            <Text>Code GEN : {product.codeGEN} || Référence : {product.reference}</Text>
            <Text>Quantité disponible : {product.quantity}</Text>
            {product.quantity < product.seuil && (
              <Flex alignItems="center">
                <WarningTwoIcon color="orange" />
                <Text ml="2" color="orange">Low Quantity - Alert Seuil [ {product.seuil} ]</Text>
              </Flex>
            )}
            <Button colorScheme="teal" onClick={() => handleLocateClick(product)}>Locate</Button>
            <Button colorScheme="teal" onClick={() => handleOpenModal(product)}>Change Quantity</Button>
          </div>
          <div style={{ marginLeft: 'auto', marginRight: '20px' }}>
            <img src={product.image} alt="Aucun aperçu..." style={{ width: '150px', height: 'auto' }} />
          </div>


          <Flex style={{ marginBottom: '155px'}}>
        <EditIcon color="yellow" onClick={handleOpenEditModal}/>
      </Flex>

  <Modal isOpen={isEMOpen} onClose={handleCloseEditModal}>
  <ModalOverlay />
  <ModalContent>
    <ModalHeader>Edit Product</ModalHeader>
    <ModalCloseButton />
    <ModalBody>
      <Text>Nom du produit : {product.name}</Text>
      <Text>Référence : {product.reference}</Text>
      <Text>Seuil Produit :</Text>
      <Input
        type="number"
        value={seuil}
        onChange={(e) => setSeuil(e.target.value)}
      />
      <Button colorScheme="red" mr={3} onClick={handleDeleteProduct}>Supprimer</Button>
      <Button colorScheme="blue" onClick={handleUpdateSeuil}>Enregistrer</Button>
    </ModalBody>
  </ModalContent>
</Modal>
          <Modal isOpen={isOpen} onClose={handleCloseModal}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Modifier la quantité</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text>Change Quantity  : {selectedProduct && selectedProduct.name}</Text>
                <Text>Référence : {product.reference}</Text>
                <QuantityControl quantity={selectedProduct ? selectedProduct.quantity : 0} onChange={handleQuantityChange} />
              </ModalBody>
            </ModalContent>
          </Modal>
          <style jsx>{`
              .rack-location {
                display: inline-block;
                padding: 5px;
                border-radius: 10px;
                background-color: lightgray;
                color: black;
              }
            `}</style>
        </Box>
        
        ))}
      </Grid>
      {loading && (
        <Box position="fixed" top="50%" left="50%" transform="translate(-50%, -50%)" backgroundColor="rgba(0, 0, 0, 0.7)" color="white" p="4" borderRadius="md" boxShadow="lg">
          Loading...
        </Box>
      )}
    </Flex>
  );
};

export default ProductDetailPage;
