import React, { useState, useEffect } from "react";
import {
  Flex,
  Icon,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import DashboardTableRow from "components/Tables/DashboardTableRow";

const Projects = ({ title, amount }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch(
          "https://logapp.api.mrapi.net/api/projects/all"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch projects");
        }
        const data = await response.json();
        setProjects(data);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, []);

  return (
    <Card p='16px' overflowX={{ sm: "scroll", xl: "hidden" }}>
      <CardHeader p='12px 0px 28px 0px'>
        <Flex direction='column'>
          <Text fontSize='lg' color={textColor} fontWeight='bold' pb='.5rem'>
            {title}
          </Text>
          <Flex align='center'>
            <Icon
              as={IoCheckmarkDoneCircleSharp}
              color='teal.300'
              w={4}
              h={4}
              pe='3px'
            />
            <Text fontSize='sm' color='gray.400' fontWeight='normal'>
              <Text fontWeight='bold' as='span'>
                Projets
              </Text>{" "}
              de ce mois-ci
            </Text>
          </Flex>
        </Flex>
      </CardHeader>
      <Table variant='simple' color={textColor}>
        <Thead>
          <Tr>
            <Th>Nom</Th>
            <Th>Description</Th>
            <Th>Créé le</Th>
            <Th>Modifié le</Th>
            <Th>Temps passé (en heures)</Th>
            <Th>Progression</Th>
          </Tr>
        </Thead>
        <Tbody>
          {projects.map((project) => (
            <DashboardTableRow
              key={project.id}
              name={project.name}
              description={project.description}
              images={project.images}
              created_at={project.created_at}
              updated_at={project.updated_at}
              time_spent={project.time_spent}
              progression={project.progression}
            />
          ))}
        </Tbody>
      </Table>
    </Card>
  );
};

export default Projects;
