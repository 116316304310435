import React, { useState } from 'react';
import { Button, Input } from "@chakra-ui/react";

const QuantityControl = ({ quantity, onChange }) => {
  const [newQuantity, setNewQuantity] = useState(quantity);

  const handleInputChange = (e) => {
    const value = parseInt(e.target.value);
    setNewQuantity(isNaN(value) ? '' : value);
  };

  const handleIncrement = () => {
    setNewQuantity(prevQuantity => prevQuantity + 1);
  };

  const handleDecrement = () => {
    if (newQuantity > 0) {
      setNewQuantity(prevQuantity => prevQuantity - 1);
    }
  };

  const handleUpdate = () => {
    if (newQuantity !== quantity && newQuantity >= 0) {
      onChange(newQuantity);
    }
  };

  return (
    <div>
      <Input
        type="number"
        value={newQuantity}
        onChange={handleInputChange}
        onBlur={handleUpdate}
      />
    </div>
  );
};

export default QuantityControl;
