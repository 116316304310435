import React, { useState, useEffect } from "react";
import {
  Flex,
  Icon,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import DemandeProjectTableRow from "components/Tables/DemandeProjectTableRow";

function formatDateTime(dateTimeString) {
  const options = { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit" };
  const dateTime = new Date(dateTimeString);
  return dateTime.toLocaleDateString("fr-FR", options);
}

function truncateText(text, maxLength) {
  return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
}

const InfoProjectsAccepterDemande = ({ title, amount }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    fetchProjects();
    
    const interval = setInterval(() => {
      fetchProjects();
    }, 5000);

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  const fetchProjects = async () => {
    try {
      const response = await fetch(
        "https://logapp.api.mrapi.net/api/projects/demande/list"
      );
      if (!response.ok) {
        throw new Error("Failed to fetch projects");
      }
      const data = await response.json();
      // Filter projects to only include those with an accepted status
      setProjects(data.filter(project => project.status === "accepted"));
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const handleStatusChange = async (projectId, newStatus) => {
    try {
      const response = await fetch(`https://logapp.api.mrapi.net/api/projects/update/${projectId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: newStatus }),
      });

      if (!response.ok) {
        throw new Error('Failed to update project status');
      }

      // Remove the project from the pending list
      setProjects(projects.filter(project => project.id !== projectId));

      // Trigger fetch in other components based on new status
      if (newStatus === "accepted") {
        fetchProjectsAccepte();
      } else if (newStatus === "pending") {
        fetchProjectsWaiting();
      } else if (newStatus === "rejected") {
        fetchProjectsRefuser();
      }
    } catch (error) {
      console.error('Error updating project status:', error);
    }
  };

  const fetchProjectsAccepte = async () => {
    // Fetch accepted projects in InfoProjectAccepte
  };

  const fetchProjectsWaiting = async () => {
    // Fetch waiting projects in InfoProjectWaiting
  };

  const fetchProjectsRefuser = async () => {
    // Fetch rejected projects in InfoProjectRefuser
  };


  const viewDetails = (project) => {
    setSelectedProject(project);
    onOpen();
  };

  return (
    <>
    <Card p='16px' w={{ base: "100%", md: "80%", xl: "100%" }} overflowX={{ sm: "scroll", xl: "hidden" }}>
      <CardHeader p='12px 0px 28px 0px'>
        <Flex direction='column'>
          <Text fontSize='lg' color={textColor} fontWeight='bold' pb='.5rem'>
            {title}
          </Text>
          <Flex align='center'>
            <Icon
              as={IoCheckmarkDoneCircleSharp}
              color='teal.300'
              w={4}
              h={4}
              pe='3px'
            />
            <Text fontSize='sm' color='gray.400' fontWeight='normal'>
              <Text fontWeight='bold' as='span'>
                Projet(s)
              </Text>{" "}
              Accepté(s)
            </Text>
          </Flex>
        </Flex>
      </CardHeader>
      <Table variant='simple' color={textColor}>
        <Thead>
          <Tr>
            <Th>Nom</Th>
            <Th>Description</Th>
            <Th>Créé le</Th>
            <Th>Modifié le</Th>
          </Tr>
        </Thead>
        <Tbody>
          {projects.map((project) => (
            <DemandeProjectTableRow
              key={project.id}
              id={project.id}
              name={project.title}
              description={truncateText(project.description, 30)}
              created_at={project.created_at}
              updated_at={project.updated_at}
              handleStatusChange={handleStatusChange}
              viewDetails={() => viewDetails(project)}
            />
          ))}
        </Tbody>
      </Table>
    </Card>
          <Drawer size="xl" isOpen={isOpen} placement="right" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Détails du Projet</DrawerHeader>
            <DrawerBody>
              {selectedProject && (
                <div>
                  <Text fontSize="lg" fontWeight="bold">Nom du projet: {selectedProject.title}</Text>
                  <Text fontSize="md" mt={2}><b>Description:</b> {selectedProject.description}</Text>
                  <Text fontSize="md" mt={2}><b>Specifications:</b> {selectedProject.specifications}</Text>
                  <Text fontSize="md" mt={2}><b>Budget:</b> {selectedProject.budget} €</Text>
                  <Text fontSize="md" mt={2}><b>Deadline:</b> {formatDateTime(selectedProject.deadline)}</Text>
                  <Text fontSize="md" mt={2}><b>Références:</b> {selectedProject.ref}</Text>
                  <Text fontSize="md" mt={2}><b>Contact:</b> {selectedProject.contact}</Text>
                  <Text fontSize="md" mt={2}><b>Créé le:</b> {formatDateTime(selectedProject.created_at)}</Text>
                  <Text fontSize="md" mt={2}><b>Mis à jour le:</b> {formatDateTime(selectedProject.updated_at)}</Text>
                  {selectedProject.attachments && selectedProject.attachments.length > 0 && (
                    <div>
                      <Text fontSize="md" mt={2}><b>Pièces jointes:</b></Text>
                      <ul>
                        {selectedProject.attachments.map((attachment, index) => (
                          <li key={index}>
                            <a href={attachment.url} target="_blank" rel="noopener noreferrer">
                              {attachment.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              )}
            </DrawerBody>
            <DrawerFooter>
              <Button variant="outline" mr={3} onClick={onClose}>
                Fermer
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </>
  );
};

export default InfoProjectsAccepterDemande;
