// Chakra imports
import {
    Flex,
    Grid,
    Image,
    SimpleGrid,
    useColorModeValue,
  } from "@chakra-ui/react";
  // assets
import React from 'react';
import { Heading } from '@chakra-ui/react';
import AddProductForm from "./components/AddProductForm";

const AddProductPage = () => {
  return (
    <Flex justifyContent="center" alignItems="center" h="100vh">
      <Flex direction="column" p="4" bg="white" rounded="md" shadow="md" backgroundColor="#1a202c">
        <Heading mb="4">Ajouter un Produit</Heading>
        <AddProductForm />
      </Flex>
    </Flex>
  );
};

export default AddProductPage;
