import {
  Table,
  Flex,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Badge,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import ProductTableList from "components/Tables/ProductTableList";
import React, { useEffect, useState } from "react";

const Products = ({ title }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const [products, setProducts] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const fetchProducts = async () => {
    try {
      const response = await fetch('https://logapp.api.mrapi.net/api/products/all');
      if (!response.ok) {
        throw new Error('Erreur lors de la récupération des produits');
      }
      const data = await response.json();
      setProducts(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des produits:', error);
    } finally {
      setLoaded(true);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  if (!loaded) {
    return <div>Chargement en cours...</div>;
  }

  return (
    <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
<CardHeader p="6px 0px 22px 0px">
  <Flex alignItems="center">
    <Text fontSize="xl" color={textColor} fontWeight="bold" mr="4">
      {title}
    </Text>
    <Badge variant='outline' colorScheme='red'>
      BETA
    </Badge>
  </Flex>
</CardHeader>

      <CardBody>
        <Table variant="simple" color={textColor}>
          <Tbody>
            {products.map((product) => (
              <ProductTableList
                key={product.id}
                name={product.name}
                description={product.description}
                image={product.image}
                codeGEN={product.codeGEN}
                reference={product.reference}
                quantity={product.quantity}
                seuil={product.seuil}
                rackLocation={`${product.rackNumber} ${product.rackSlot} ${product.rackHeight} ${product.rackSide}`}
                createdAt={new Date(product.created_at).toLocaleString()}
              />
            ))}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default Products;
