import {
    Avatar,
    Badge,
    Button,
    Flex,
    Td,
    Text,
    Tr,
    useColorModeValue,
  } from "@chakra-ui/react";
  import React from "react";
  import defaultProductImage from "../../assets/img/default_product.png";
  
  function ProductTableList(props) {
    const { image, name, description, codeGEN, reference, quantity, seuil, rackLocation, createdAt } = props;
    const textColor = useColorModeValue("gray.700", "white");
    const bgStatus = useColorModeValue("gray.400", "#1a202c");
    const colorStatus = useColorModeValue("white", "gray.400");
    const productImage = image || defaultProductImage;
  
    return (
      <Tr>
        <Td minWidth={{ sm: "250px" }} pl="0px">
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Avatar src={productImage} w="50px" borderRadius="12px" me="18px" />
            <Flex direction="column">
              <Text
                fontSize="md"
                color={textColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {name}
              </Text>
              <Text fontSize="sm" color="gray.400" fontWeight="normal">
                {description || "-"}
              </Text>
            </Flex>
          </Flex>
        </Td>
  
        <Td>
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {codeGEN}
          </Text>
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {reference}
          </Text>
        </Td>
        <Td>
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {quantity}
          </Text>
          <Text fontSize="sm" color="orange.400" fontWeight="normal">
            {seuil || "-"}
          </Text>
        </Td>
        <Td>
          <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
            {rackLocation}
          </Text>
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            C: {createdAt}
          </Text>
        </Td>
      </Tr>
    );
  }
  
  export default ProductTableList;
  