import React, { useState, useEffect } from 'react';
import { Box, Text, Image } from '@chakra-ui/react';

const Notification = () => {
  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    const fetchAlerts = async () => {
      try {
        const response = await fetch('https://applogapi.mariabird.net/api/AlertProduct');
        if (response.ok) {
          const data = await response.json();
          setAlerts(data);
        } else {
          throw new Error('Failed to fetch alerts');
        }
      } catch (error) {
        console.error('Error fetching alerts:', error);
      }
    };

    fetchAlerts();
  }, []);

  return (
    <Box>
      <Text fontSize="xl" fontWeight="bold" mb="4">Notification & Alerts de seuil :</Text>
      {alerts.map((alert) => (
        <Box key={alert.id} mb="4" display="flex" alignItems="center">
          <Image src={alert.image} alt={alert.name} boxSize="50px" mr="4" />
          <Box>
            <Text>{`${alert.name}`}</Text>
            <Text>{`Quantité : ${alert.quantity}`}</Text>
            <Text>{`Alert Seuil : ${alert.seuil}`}</Text>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default Notification;
